import { LoadingScreen } from '@ppui/ui-components'
import { AppContainer } from 'components/AppContainer'
import { observer } from 'mobx-react'
import { isFalse } from 'ramda-adjunct'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { FEATURES } from './constants/features'
import { useAuth } from './contexts'
import { useGetUserProfile } from './lib/queries/useGetUserProfile'
import { AccountType } from './lib/types'
import { isAppEnabled } from './lib/utils'
import { Retry } from './Retry'
import { useStores } from './store/rootStore'

const NotFound = React.lazy(
  async () => await Retry(async () => await import('./routes/Investor/NotFound'))
)

export const RequireAuth = observer(
  ({
    children,
    featureFlag,
    fullWidth = false,
    accessFor = AccountType.INVESTOR,
  }: {
    children: JSX.Element
    featureFlag?: string
    fullWidth?: boolean
    accessFor?: AccountType
  }): JSX.Element => {
    const { featureFlagsStore } = useStores()
    const isRequestAccessPageEnabled: boolean = featureFlagsStore.isFeatureFlagEnabled(
      FEATURES.ENABLE_REQUEST_ACCESS_PAGE_IOI
    )
    const { isAuthenticated, hasLoaded } = useAuth()
    const { data, isLoading } = useGetUserProfile()
    const location = useLocation()

    if (!hasLoaded) {
      return <LoadingScreen />
    }

    if (!isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return (
        <Navigate
          to={`/login?${location?.pathname.slice(1) ?? ''}${location?.search}` ?? ''}
          replace
        />
      )
    }

    if (isLoading || data === undefined) {
      return <LoadingScreen />
    }

    // Redirect to Request access page if user is not enabled to IOI
    if (isFalse(isAppEnabled(data.user?.applicationsEnabled)) && isRequestAccessPageEnabled) {
      return <Navigate to="/request-access" replace />
    }

    const responseWasReceived = featureFlagsStore.hasResponse()
    const isPageEnabled =
      featureFlag !== undefined ? featureFlagsStore.isFeatureFlagEnabled(featureFlag) : true

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!responseWasReceived) {
      return (
        <AppContainer isAuthenticated fullWidth={fullWidth}>
          <LoadingScreen />
        </AppContainer>
      )
    }

    if (!isPageEnabled || accessFor !== data.user.accountType) {
      return (
        <AppContainer isAuthenticated fullWidth={fullWidth}>
          <NotFound />
        </AppContainer>
      )
    }

    return (
      <AppContainer isAuthenticated fullWidth={fullWidth}>
        <div>{children}</div>
      </AppContainer>
    )
  }
)
