import { useGetFeatureFlags } from '../queries/useGetFeatureFlags'

export function useFeatureFlags(): {
  isFeatureFlagEnabled: (featureFlagName: string) => boolean
} {
  const featureFlagsQuery = useGetFeatureFlags()

  const isFeatureFlagEnabled = (featureFlagName: string): boolean =>
    (featureFlagsQuery.data ?? []).find(
      (featureFlag) => featureFlag.name === featureFlagName
    )?.enabled ?? false

  return { isFeatureFlagEnabled }
}
