import { makeAutoObservable } from 'mobx'
import { RootStore } from 'src/store/rootStore'

import { BUNDLE_MODE } from '../commonTypes'

export class BundleStore {
  private readonly rootStore: RootStore
  private _bundleMode: BUNDLE_MODE = BUNDLE_MODE.IOI

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set bundleMode(value: BUNDLE_MODE) {
    this._bundleMode = value
  }

  get bundleMode(): BUNDLE_MODE {
    return this._bundleMode
  }
}
