import { Toast } from '@ppui/ui-components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStores } from 'src/store/rootStore'

export const AlertComponent: React.FC = observer(() => {
  const { alertStore } = useStores()

  const alertData = alertStore.alertData
  if (alertData.message === null || alertData.type === null || alertStore.shouldBeClosed) {
    return null
  }
  return (
    <div className="fixed top-20 right-3 z-40 w-96">
      <Toast
        type={alertData.type}
        title={alertData.message?.title}
        message={alertData.message?.description}
        onCloseClick={() => alertStore.closeAlert()}
      />
    </div>
  )
})
