import React from 'react'
import { BundleStore } from 'src/store/bundleStore/bundleStore'
import { FeatureFlagsStore } from 'src/store/featureFlagsStore/featureFlagsStore'
import { OrderStore } from 'src/store/orderStore/orderStore'

import { AlertStore } from './alertStore/alertStore'
import { AlreadyActionedPanelStore } from './componentStateStore/alreadyActionedPanelStore'
import { SelfAcknowledgementContainerStore } from './componentStateStore/selfAcknowledgementContainerStore'

export class RootStore {
  featureFlagsStore: FeatureFlagsStore
  orderStore: OrderStore
  bundleStore: BundleStore
  selfAcknowledgementContainerStore: SelfAcknowledgementContainerStore
  alreadyActionedPanelStore: AlreadyActionedPanelStore
  alertStore: AlertStore

  constructor() {
    this.featureFlagsStore = new FeatureFlagsStore(this)
    this.orderStore = new OrderStore(this)
    this.bundleStore = new BundleStore(this)
    this.selfAcknowledgementContainerStore = new SelfAcknowledgementContainerStore(this)
    this.alreadyActionedPanelStore = new AlreadyActionedPanelStore(this)
    this.alertStore = new AlertStore(this)
  }
}

export const rootStore = new RootStore()
const RootStoreContext = React.createContext(rootStore)
export const useStores = (): RootStore => React.useContext(RootStoreContext)
