import { CACHED_QUERY_PROPS } from '../../constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { getFeatureFlags } from '../../services/feature-flags'
import { FeatureFlagsDto } from '../../store/featureFlagsStore/types'
import { ReactQueryKey } from '../types'

export function useGetFeatureFlags(): UseQueryResult<FeatureFlagsDto[]> {
  return useQuery<FeatureFlagsDto[]>(ReactQueryKey.FEATURE_FLAGS, getFeatureFlags, {
    ...CACHED_QUERY_PROPS,
    staleTime: 30 * 60 * 1000,
    retry: true,
    retryDelay: 3000
  })
}
