import { makeAutoObservable } from 'mobx'
import { RootStore } from 'src/store/rootStore'

export class SelfAcknowledgementContainerStore {
  private readonly rootStore: RootStore
  private _currentState: { orderId: string | null; isOpen: boolean } = {
    orderId: null,
    isOpen: false,
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get currentState(): { orderId: string | null; isOpen: boolean } {
    return this._currentState
  }

  set currentState({ orderId, isOpen }: { orderId: string | null; isOpen: boolean }) {
    this._currentState = { orderId, isOpen }
  }

  close(): void {
    this._currentState = {
      orderId: null,
      isOpen: false,
    }
  }
}
