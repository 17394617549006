import { LoadingScreen } from '@ppui/ui-components'
import ky from 'ky'
import React from 'react'
import ReactDOM from 'react-dom'
import { configureGoogleAnalytics } from 'src/config/configureGoogleAnalytics'

import { App } from './App'
import { configureAmplify } from './configureAmplify'
import { Providers } from './Providers'
import { ClientConfigDTO } from './types'

ReactDOM.render(<LoadingScreen />, document.getElementById('root'))

// TODO configure absolute imports
// TODO configure sentry
// TODO show skeleton app before config is loaded
ky.get('/api/v1/client-config')
  .json<ClientConfigDTO>()
  .then(async (config: ClientConfigDTO) => {
    configureAmplify(config.cognitoRegion, config.cognitoUserPoolId, config.cognitoAppClientId)
    configureGoogleAnalytics(config.googleAnalyticsTrackingId)
    ReactDOM.render(
      <Providers envName={config.envName}>
        <App envName={config.envName} />
      </Providers>,
      document.getElementById('root')
    )
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('ConfigLoadError', error)
    ReactDOM.render(<h1>error</h1>, document.getElementById('root'))
  })
