import ContentMarginContainer from 'components/ContentMarginContainer/ContentMarginContainer'
import SecondaryHeader from 'components/Header/SecondaryHeader'
import { NoMobileVersionMessage } from 'components/NoMobileVersionMessage/NoMobileVersionMessage'
import React, { ReactNode } from 'react'
import { useWindowSize } from 'src/lib/hooks/useWindowSize'

interface Props {
  children: ReactNode
  isAuthenticated: boolean
}

export function AppContainerWithMargins({ children, isAuthenticated }: Props): JSX.Element {
  const { width } = useWindowSize()

  const isYMarginShown = width > 1280

  return (
    <>
      <div className="bg-grey_200 hidden md:flex flex-col">
        <div>
          {isAuthenticated && (
            <div className="flex flex-grow ml-2">
              {isYMarginShown && <ContentMarginContainer />}
              <SecondaryHeader />
              {isYMarginShown && <ContentMarginContainer />}
            </div>
          )}
        </div>
        <div className="flex flex-grow">
          {isYMarginShown && isAuthenticated && <ContentMarginContainer />}
          <div className="flex flex-grow flex-col mx-auto max-w-[1280px] min-w-[1280px]">
            <main className="">
              <div>{children ?? <span />}</div>
            </main>
          </div>
          {isYMarginShown && isAuthenticated && <ContentMarginContainer />}
        </div>
      </div>
      <div className="h-screen flex md:hidden flex-col">
        <div className="h-full max-w-lg">
          <NoMobileVersionMessage />
        </div>
      </div>
    </>
  )
}
