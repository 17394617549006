import { makeAutoObservable } from 'mobx'
import { RootStore } from 'src/store/rootStore'

export class OrderStore {
  private readonly rootStore: RootStore
  private _isValidNewForm: boolean = false
  private _isValidEditForm: boolean = true
  private _isOrderDetailsEdited: boolean = false
  private _isLimitChanged: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set isLimitChanged(value: boolean) {
    this._isLimitChanged = value
  }

  get isLimitChanged(): boolean {
    return this._isLimitChanged
  }

  set isValidNewForm(value: boolean) {
    this._isValidNewForm = value
  }

  get isValidNewForm(): boolean {
    return this._isValidNewForm
  }

  set isValidEditForm(value: boolean) {
    this._isValidEditForm = value
  }

  get isValidEditForm(): boolean {
    return this._isValidEditForm
  }

  set isOrderDetailsEdited(value: boolean) {
    this._isOrderDetailsEdited = value
  }

  get isOrderDetailsEdited(): boolean {
    return this._isOrderDetailsEdited
  }
}
