import { makeAutoObservable } from 'mobx'

import { RootStore } from '../rootStore'

export class AlreadyActionedPanelStore {
  private readonly rootStore: RootStore
  private _isOpen: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get isOpen(): boolean {
    return this._isOpen
  }

  set isOpen(value: boolean) {
    this._isOpen = value
  }
}
