import { AccountRole, AccountType, DistributionStatus } from 'src/lib/types'

import { API } from './aws-api'

export interface ProfileDTO {
  user: {
    email: string
    givenName: string
    familyName: string
    organizationId: string
    organizationName: string
    accountRole: AccountRole
    accountType: AccountType
    termsAndConditionsConsent?: boolean
    distributionStatusOverride: DistributionStatus
    applicationsEnabled?: string[]
  }
  organization: {
    logo?: string
  }
}

export async function getUserProfile(): Promise<ProfileDTO> {
  return await API<ProfileDTO>().get({ path: '/v1/profile' })
}

export async function requestAccess(): Promise<void> {
  void (await API().post({ path: '/v1/profile/request-access' }))
}
