import { makeAutoObservable, runInAction } from 'mobx'
import { getFeatureFlags } from 'src/services/feature-flags'
import { FeatureFlagsDto } from 'src/store/featureFlagsStore/types'
import { RootStore } from 'src/store/rootStore'

export class FeatureFlagsStore {
  private readonly rootStore: RootStore
  private featureFlagsList: FeatureFlagsDto[] = []
  private isLoading: boolean = false
  private responseWasReceived: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  hasResponse(): boolean {
    return this.responseWasReceived
  }

  isFeatureFlagEnabled(featureFlagName: string): boolean {
    void this.getFeatureFlags()
    return (
      this.featureFlagsList.find((featureFlag) => featureFlag.name === featureFlagName)?.enabled ??
      false
    )
  }

  async getFeatureFlags(): Promise<FeatureFlagsDto[]> {
    if (this.responseWasReceived || this.isLoading) {
      return this.featureFlagsList
    }
    try {
      runInAction(() => (this.isLoading = true))
      const featureFlags = await getFeatureFlags()
      runInAction(() => (this.featureFlagsList = featureFlags))
      runInAction(() => (this.responseWasReceived = true))
    } catch (e) {
      runInAction(() => (this.responseWasReceived = false))
    }
    runInAction(() => (this.isLoading = false))
    return this.featureFlagsList
  }
}
