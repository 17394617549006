export const isExitModalVisible = (pathname: string, to: string): boolean =>
  (pathname.includes('preferences') ||
    pathname.includes('new-order') ||
    pathname.includes('edit-order-indication')) &&
  pathname !== to

export const ecmLinks = (env: string, page: 'dashboard' | 'contacts' | 'relationships'): string => {
  switch (page) {
    case 'dashboard':
      return `https://${env}.primaryportal.com/ecm/dashboard`
    case 'contacts':
      return `https://${env}.primaryportal.com/ecm/investor/syndicate-contacts`
    case 'relationships':
      return `https://${env}.primaryportal.com/ecm/investor/banker-connections`
  }
}
