import { isNotNull } from 'ramda-adjunct'
import { useEffect, useLayoutEffect, useState } from 'react'

interface WindowPropInterface {
  width: number
  height: number
}
// Hook
export function useWindowSize(): WindowPropInterface {
  const [windowSize, setWindowSize] = useState<WindowPropInterface>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const queryCall = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  // Handler to call on window resize
  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', queryCall)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', queryCall)
  }, []) // Empty array ensures that effect is only run on mount

  useLayoutEffect(() => {
    const window = {
      type: 'desktop',
      collapsed: false,
    }

    if (isNotNull(windowSize.width) && windowSize.width > 1200) {
      // On bigger screens (>1200px) - it defaults to open
      window.type = 'desktop'
      window.collapsed = false
    } else if (isNotNull(windowSize.width) && windowSize.width < 1200 && windowSize.width > 766) {
      // On smaller screens (1200px>, tablets) - it will default to collapse
      window.type = 'tablet'
      window.collapsed = true
    } else {
      // On mobile screens - it will default to hidden(collapsed)
      window.type = 'mobile'
      window.collapsed = true
    }
  }, [windowSize.width])

  return windowSize
}
