import { Cog8ToothIcon, CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import { anyPass, equals } from 'ramda'
import React from 'react'
import { AccountType } from 'src/lib/types'

interface LinkItem {
  icon: JSX.Element
  url: string
  text: string
  dataQa: string
  isAvailable: boolean
}

export const getSecondaryNavLinks = (accountType: AccountType): LinkItem[] => {
  return [
    {
      icon: <CursorArrowRaysIcon className="mt-0.5 w-4 h-4" />,
      url: '/order-indications',
      text: 'Order indications overview',
      dataQa: 'ioi.secondary-header.order-indications',
      isAvailable: equals(accountType, AccountType.INVESTOR),
    },
    {
      icon: <Cog8ToothIcon className="mt-0.5 w-4 h-4" />,
      url: '/preferences',
      text: 'Preferences',
      dataQa: 'ioi.secondary-header.preferences',
      isAvailable: equals(accountType, AccountType.INVESTOR),
    },
    {
      icon: <CursorArrowRaysIcon className="mt-0.5 w-4 h-4" />,
      url: '/deals',
      text: 'Indication overview',
      dataQa: 'ioi.secondary-header.deals',
      isAvailable: anyPass([equals(AccountType.BANKER), equals(AccountType.SELL_SIDE_SALES)])(
        accountType
      ),
    },
  ]
}
